<script setup lang="ts">
import type { LoginMethod } from '@@/bits/auth_helper'
import device from '@@/bits/device'
import fetchElectronBridge, { URLTarget } from '@@/bits/electron_bridge'
import { __ } from '@@/bits/intl'
import { getSearchParams, navigateTo, transformUrl } from '@@/bits/location'
import OzDropdownButton, {
  OzDropdownButtonColorScheme,
  OzDropdownButtonSizePreset,
} from '@@/library/v4/components/OzDropdownButton.vue'
import { useAuthLoginStore } from '@@/pinia/auth_login_store'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import AuthLoginThirdPartyButton from '@@/vuecomponents/AuthLoginThirdPartyButton.vue'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    loginMethods?: Record<string, LoginMethod>
    type?: AuthLoginThirdPartyGroupButtonsType
    showMoreDropdown?: boolean
  }>(),
  {
    loginMethods: () => ({}),
    type: AuthLoginThirdPartyGroupButtonsType.Continue,
    showMoreDropdown: true,
  },
)

const { isDarkMode } = storeToRefs(useDarkModeStore())
const { isSchoolLibraryLoginPage } = storeToRefs(useAuthLoginStore())

const isApple = device.mac || device.ios

const xAdditionalLoginOptionsList = ref<boolean>(false)
const areAdditionalLoginOptionsAvailable = computed<boolean>(
  () =>
    props.loginMethods.skoletube != null || props.loginMethods.classlink != null || props.loginMethods.clever != null,
)

const appleText = computed<string>(() => {
  return {
    [AuthLoginThirdPartyGroupButtonsType.Continue]: __('Continue with Apple'),
    [AuthLoginThirdPartyGroupButtonsType.LogIn]: __('Log in with Apple'),
    [AuthLoginThirdPartyGroupButtonsType.SignUp]: __('Sign up with Apple'),
  }[props.type]
})

const googleText = computed<string>(() => {
  return {
    [AuthLoginThirdPartyGroupButtonsType.Continue]: __('Continue with Google'),
    [AuthLoginThirdPartyGroupButtonsType.LogIn]: __('Log in with Google'),
    [AuthLoginThirdPartyGroupButtonsType.SignUp]: __('Sign up with Google'),
  }[props.type]
})

const microsoftText = computed<string>(() => {
  return {
    [AuthLoginThirdPartyGroupButtonsType.Continue]: __('Continue with Microsoft'),
    [AuthLoginThirdPartyGroupButtonsType.LogIn]: __('Log in with Microsoft'),
    [AuthLoginThirdPartyGroupButtonsType.SignUp]: __('Sign up with Microsoft'),
  }[props.type]
})

const skoletubeText = computed<string>(() => {
  return {
    [AuthLoginThirdPartyGroupButtonsType.Continue]: __('Continue with Skoletube'),
    [AuthLoginThirdPartyGroupButtonsType.LogIn]: __('Log in with Skoletube'),
    [AuthLoginThirdPartyGroupButtonsType.SignUp]: __('Sign up with Skoletube'),
  }[props.type]
})

const classlinkText = computed<string>(() => {
  return {
    [AuthLoginThirdPartyGroupButtonsType.Continue]: __('Continue with ClassLink'),
    [AuthLoginThirdPartyGroupButtonsType.LogIn]: __('Log in with ClassLink'),
    [AuthLoginThirdPartyGroupButtonsType.SignUp]: __('Sign up with ClassLink'),
  }[props.type]
})

const cleverText = computed<string>(() => {
  return {
    [AuthLoginThirdPartyGroupButtonsType.Continue]: __('Continue with Clever'),
    [AuthLoginThirdPartyGroupButtonsType.LogIn]: __('Log in with Clever'),
    [AuthLoginThirdPartyGroupButtonsType.SignUp]: __('Sign up with Clever'),
  }[props.type]
})

const handleClick = (url: string | undefined): void => {
  if (!url) return
  const electronApp = fetchElectronBridge()
  const referralCode = getSearchParams().referral_code
  const oauthUrl = referralCode
    ? transformUrl(url, {
        searchParams: {
          referralCode,
        },
      })
    : url

  if (electronApp.openThirdPartyAuth) {
    return electronApp.openThirdPartyAuth(oauthUrl, URLTarget.Internal)
  }

  navigateTo(oauthUrl)
}
</script>

<script lang="ts">
export enum AuthLoginThirdPartyGroupButtonsType {
  Continue = 'Continue',
  LogIn = 'Log in',
  SignUp = 'Sign up',
}
</script>

<template>
  <div>
    <div class="flex flex-col space-y-2 pb-2">
      <!--
      - On iOS devices: apple, google, ms, skoletube, classlink, clever
      - On non iOS devices: google, ms, apple, skoletube, classlink, clever
    -->
      <AuthLoginThirdPartyButton
        v-if="loginMethods.apple && isApple"
        icon="apple"
        :text="appleText"
        @click="handleClick(loginMethods.apple.url)"
      />

      <AuthLoginThirdPartyButton
        v-if="loginMethods.google"
        icon="google"
        :text="googleText"
        @click="handleClick(loginMethods.google.url)"
      />

      <AuthLoginThirdPartyButton
        v-if="loginMethods.microsoft"
        icon="windows"
        :text="microsoftText"
        @click="handleClick(loginMethods.microsoft.url)"
      />

      <AuthLoginThirdPartyButton
        v-if="loginMethods.apple && !isApple"
        icon="apple"
        :text="appleText"
        @click="handleClick(loginMethods.apple.url)"
      />
      <div
        v-if="
          !isSchoolLibraryLoginPage &&
          !xAdditionalLoginOptionsList &&
          areAdditionalLoginOptionsAvailable &&
          showMoreDropdown
        "
        class="flex justify-end"
      >
        <OzDropdownButton
          class="px-3 gap-x-1"
          :text="__('More options')"
          :size-preset="OzDropdownButtonSizePreset.H32px"
          :color-scheme="OzDropdownButtonColorScheme.SecondaryClear"
          :text-color-classes="['text-dark-text-200 dark:text-light-text-200']"
          @click="xAdditionalLoginOptionsList = true"
        />
      </div>
      <template v-else>
        <AuthLoginThirdPartyButton
          v-if="loginMethods.skoletube"
          icon="skoletube"
          :text="skoletubeText"
          @click="handleClick(loginMethods.skoletube.url)"
        />

        <AuthLoginThirdPartyButton
          v-if="loginMethods.classlink"
          icon="classlink"
          :text="classlinkText"
          @click="handleClick(loginMethods.classlink.url)"
        />

        <AuthLoginThirdPartyButton
          v-if="loginMethods.clever"
          :icon="isDarkMode ? 'clever_blue' : 'clever_white'"
          :text="cleverText"
          @click="handleClick(loginMethods.clever.url)"
        />
      </template>
    </div>
  </div>
</template>
