// @file The store for the AuthLoginApp
import { normalizeThirdPartyLoginMethods } from '@@/bits/auth_helper'
import getCsrfToken from '@@/bits/csrf_token'
import { captureException, captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __, p__ } from '@@/bits/intl'
import { clearQueryParamsWithoutReloading, navigateTo, transformCurrentUrl } from '@@/bits/location'
import { addQueryParams } from '@@/bits/url'
import { ApiErrorCode, HttpCode, SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { JsonApiData, JsonApiResponse } from '@@/types'
import { FetchError, fetchJson, fetchResponse, HTTPMethod } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface LoginMethod {
  name: string
  url?: string
  displayName?: string
  logo?: string
}

interface SamlLoginMethod extends LoginMethod {
  url: string
  devMode?: boolean | null
}

interface LoginManifestResponse {
  loginMethods: LoginMethod[]
  userLoginMethods: LoginMethod[]
  samlLoginMethods: SamlLoginMethod[]
  name: string
  logo: string
}

interface LoginResponse {
  loginUrl: string
}

export const useAuthLoginStore = defineStore('authLoginStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()

  // State
  const username = ref('')
  const password = ref('')
  const usernameValidationMessage = ref('')
  const passwordValidationMessage = ref('')
  const isUsernameVerified = ref(false)
  const isAwaitingServerResponse = ref(false)
  const codeChallenge = ref<string | null>(null)
  const originAppClientId = ref<string | null>(null)
  const pkceState = ref<string | null>(null)
  const originAppRedirectUri = ref<string | null>(null)
  const codeChallengeMethod = ref<string | null>(null)
  const loginMethods = ref<LoginMethod[]>([])
  const userLoginMethods = ref<LoginMethod[]>([])
  const samlLoginMethods = ref<SamlLoginMethod[]>([])
  const schoolLibraryId = ref<string | null>(null)
  const schoolLibraryName = ref<string | null>(null)

  // Getters

  const thirdPartyAuthLoginMethods = computed(
    (): Record<string, LoginMethod> => normalizeThirdPartyLoginMethods(loginMethods.value),
  )
  const userThirdPartyAuthLoginMethods = computed(
    (): Record<string, LoginMethod> => normalizeThirdPartyLoginMethods(userLoginMethods.value),
  )
  const isUsernameValid = computed((): 'yes' | 'no' => (usernameValidationMessage.value === '' ? 'yes' : 'no'))
  const isPasswordValid = computed((): 'yes' | 'no' => (passwordValidationMessage.value === '' ? 'yes' : 'no'))
  const isPkceEnabled = computed(
    (): boolean => codeChallenge.value !== null && codeChallengeMethod.value !== null && pkceState.value !== null,
  )
  const isPkceLogin = computed(
    (): boolean =>
      codeChallenge.value !== null &&
      codeChallengeMethod.value !== null &&
      pkceState.value !== null &&
      originAppClientId.value !== null &&
      originAppRedirectUri.value !== null,
  )
  const isPasswordLoginEnabled = computed(() =>
    [...userLoginMethods.value, ...loginMethods.value].some((method) => method.name === 'password'),
  )

  const isSchoolLibraryLoginPage = computed(() => schoolLibraryId.value !== null)

  // Actions

  const resetToVerificationScreen = (): void => {
    isUsernameVerified.value = false
    setPassword('')
    setPasswordValidationMessage('')
  }

  async function initialize(startingState: {
    username?: string
    email?: string
    loginMethods: LoginMethod[]
    samlLoginMethods: SamlLoginMethod[] | null
    originAppClientId?: string | null
    pkceState?: string | null
    codeChallenge?: string | null
    codeChallengeMethod?: string | null
    originAppRedirectUri?: string
    errorCode?: string
    erroredSsoService?: string
    schoolLibraryId?: string | undefined
    schoolLibraryName?: string | undefined
  }): Promise<void> {
    if (startingState.schoolLibraryId !== undefined) {
      schoolLibraryId.value = startingState.schoolLibraryId
    }
    if (startingState.schoolLibraryName !== undefined) {
      schoolLibraryName.value = startingState.schoolLibraryName
    }

    if (startingState.username != null || startingState.email != null) {
      username.value = (startingState.username ?? startingState.email) as string
      await fetchUserLoginManifest()
      if (usernameValidationMessage.value === '') {
        window.history.pushState({}, '', window.location.pathname)
        window.addEventListener('popstate', resetToVerificationScreen)
        isUsernameVerified.value = true
      }
    }

    if (startingState.loginMethods != null) {
      loginMethods.value = startingState.loginMethods
    }
    if (startingState.samlLoginMethods != null) {
      samlLoginMethods.value = startingState.samlLoginMethods.filter((method) => {
        // Before the SAML setup launch, SAML connections don't have a devMode field, so we show them by default
        // After the launch, they have a devMode field, so we only show them if the dev mode is disabled
        if (method.devMode == null || !method.devMode) return true
        // Only show SAML login methods if the URL has the param `saml_setup=1` and the dev mode is enabled
        return isAppUsing('samlSetup') && method.devMode
      })
    }

    if (startingState.originAppClientId != null) {
      originAppClientId.value = startingState.originAppClientId
    }

    if (startingState.pkceState != null) {
      pkceState.value = startingState.pkceState
    }

    if (startingState.codeChallenge != null) {
      codeChallenge.value = startingState.codeChallenge
    }

    if (startingState.codeChallengeMethod != null) {
      codeChallengeMethod.value = startingState.codeChallengeMethod
    }

    if (startingState.originAppRedirectUri != null) {
      originAppRedirectUri.value = startingState.originAppRedirectUri
    }

    if (startingState.errorCode != null && startingState.erroredSsoService != null) {
      clearQueryParamsWithoutReloading()
      openThirdPartyAuthErrorSnackbar({
        errorCode: startingState.errorCode,
        erroredSsoService: startingState.erroredSsoService,
      })
    } else if (startingState.errorCode != null) {
      clearQueryParamsWithoutReloading()
      // handle more generic errors not specific to a third party auth service
      openErrorSnackbar({
        errorCode: startingState?.errorCode,
      })
    }
  }

  function openThirdPartyAuthErrorSnackbar({
    errorCode,
    erroredSsoService,
  }: {
    errorCode: string
    erroredSsoService: string
  }): void {
    if (errorCode === 'SSO_INVALID_INFO') {
      globalSnackbarStore.setSnackbar({
        message: p__(
          'Error message when user fails to login with Single Sign On (SSO), e.g. Google, Microsoft, and Apple because the email is invalid',
          'Sorry, your %{erroredSsoService} account email is most likely invalid. Please contact your administrator to create an account for you.',
          { erroredSsoService },
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else if (errorCode === 'SSO_AUTO_LINKING_DISALLOWED') {
      globalSnackbarStore.setSnackbar({
        message: p__(
          'Error message displayed when the user fails to log in with their Single Sign On (SSO) accounts - e.g. Login with Google, Microsoft, or Apple, because we do not allow auto linking of accounts, due to lack of verification or mismatch in account details',
          'Sorry, we could not log you in using %{erroredSsoService}. Please log in using your email and password instead.',
          { erroredSsoService },
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else if (errorCode === 'SSO_OPTION_DISALLOWED') {
      globalSnackbarStore.setSnackbar({
        message: p__(
          'Error message displayed when the user fails to log in with their Single Sign On (SSO) accounts - e.g. Login with Google, Microsoft, or Apple, because the option is disallowed',
          'Your school only allows one of the approved log in methods below',
          { erroredSsoService },
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else {
      globalSnackbarStore.genericFetchError()
    }
  }

  function openErrorSnackbar({ errorCode }: { errorCode: string }): void {
    if (errorCode === 'LOGIN_TOKEN_EXPIRED' || errorCode === 'SELECT_ACCOUNT_TOKEN_EXPIRED') {
      globalSnackbarStore.setSnackbar({
        message: __('Login token expired'),
        notificationType: SnackbarNotificationType.error,
        persist: true,
      })
    } else if (errorCode === 'NO_USER') {
      globalSnackbarStore.setSnackbar({
        message: p__(
          'Error message when a user is not found for given email or username',
          'Sorry, we could not find a user with the email or username you provided. Please try again.',
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else if (errorCode === 'VERIFICATION_ATTEMPT_LIMIT_EXCEEDED') {
      globalSnackbarStore.setSnackbar({
        message: __('You’ve reached the limit for verification attempts. Please try again in one hour.'),
        notificationType: SnackbarNotificationType.error,
      })
    } else {
      globalSnackbarStore.genericFetchError()
    }
  }

  function setUsername(value: string): void {
    username.value = value
  }

  function setPassword(value: string): void {
    password.value = value
  }

  function setUsernameValidationMessage(message: string): void {
    usernameValidationMessage.value = message
  }

  function setPasswordValidationMessage(message: string): void {
    passwordValidationMessage.value = message
  }

  async function setIsUsernameVerified(value: boolean): Promise<void> {
    isUsernameVerified.value = value
  }

  async function continueWithEmailOrUsername(): Promise<void> {
    if (username.value === '') return
    const canLogin: boolean = await checkIfUserCanLogin()
    if (!canLogin) return
    await fetchUserLoginManifest()
  }

  async function checkIfUserCanLogin(): Promise<boolean> {
    try {
      isAwaitingServerResponse.value = true

      const url = transformCurrentUrl(
        {},
        {
          path: '/api/1/auth/check-if-can-login',
          search: { email_or_username: username.value },
        },
      )
      await fetchResponse(url, {
        method: HTTPMethod.get,
      })
      return true
    } catch (e) {
      if (!(e instanceof FetchError)) {
        captureException(e)
      } else {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const error = parsedErrorMessage.errors[0]

          if (error.code === ApiErrorCode.NO_USER) {
            setUsernameValidationMessage(__('User not found'))
          } else {
            captureFetchException(e, { source: 'AuthLoginCheckIfUserCanLogin' })
          }
        } catch (jsonException) {
          captureException(jsonException)
        }
      }

      return false
    } finally {
      isAwaitingServerResponse.value = false
    }
  }

  async function fetchUserLoginManifest(): Promise<void> {
    try {
      isAwaitingServerResponse.value = true

      const queryParams: { email_or_username: string; school_library_id?: string } = {
        email_or_username: username.value,
      }

      if (isSchoolLibraryLoginPage.value) {
        queryParams.school_library_id = schoolLibraryId.value as string
      }
      const baseUrl = transformCurrentUrl(
        {},
        {
          path: '/api/auth/login',
          search: queryParams,
        },
      )
      const requestUrl = isPkceEnabled.value
        ? addQueryParams(
            baseUrl,
            `code_challenge=${codeChallenge.value as string}&code_challenge_method=${
              codeChallengeMethod.value as string
            }&redirect_uri=${encodeURIComponent(originAppRedirectUri.value as string)}&client_id=${
              originAppClientId.value as string
            }&pkce_state=${pkceState.value as string}`,
          )
        : baseUrl
      const response: JsonApiResponse<LoginManifestResponse> = await fetchJson(requestUrl, {
        method: HTTPMethod.get,
      })
      const data = response.data as JsonApiData<LoginManifestResponse>
      userLoginMethods.value =
        data?.attributes?.userLoginMethods?.length > 0
          ? data?.attributes?.userLoginMethods
          : data?.attributes.loginMethods
      samlLoginMethods.value = data?.attributes?.samlLoginMethods ?? []
      isUsernameVerified.value = true
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.status === HttpCode.NotFound) {
          try {
            const parsedErrorMessage = JSON.parse(e.message)
            const error = parsedErrorMessage.errors[0]

            if (error.code === ApiErrorCode.NO_USER) {
              setUsernameValidationMessage(__('User not found'))
            }
          } catch (jsonException) {
            captureException(jsonException)
          }
        } else {
          captureFetchException(e, { source: 'AuthLoginFetchUserLoginManifest' })
        }
      } else {
        captureException(e)
      }
    } finally {
      isAwaitingServerResponse.value = false
    }
  }

  async function loginWithPassword(): Promise<void> {
    if (username.value === '' || password.value === '') return

    try {
      isAwaitingServerResponse.value = true

      const url = `/api/auth/login`
      const response: JsonApiResponse<LoginResponse> = await fetchJson(url, {
        method: HTTPMethod.post,
        csrfToken: getCsrfToken(),
        jsonData: { username: username.value, password: password.value },
      })
      const loginUrl = (response.data as JsonApiData<LoginResponse>)?.attributes?.loginUrl
      if (isPkceLogin.value) {
        const pkceLoginUrl = addQueryParams(
          loginUrl,
          `code_challenge=${codeChallenge.value as string}&code_challenge_method=${
            codeChallengeMethod.value as string
          }&redirect_uri=${encodeURIComponent(originAppRedirectUri.value as string)}&client_id=${
            originAppClientId.value as string
          }&pkce_state=${pkceState.value as string}`,
        )
        navigateTo(pkceLoginUrl)
      } else {
        navigateTo(loginUrl)
      }
    } catch (e) {
      isAwaitingServerResponse.value = false

      if ([HttpCode.UnprocessableEntity, HttpCode.RateLimitError].includes(e.status)) {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const error = parsedErrorMessage.errors[0]

          if (error.code === ApiErrorCode.NO_USER) {
            setUsernameValidationMessage(__('User not found'))
          } else if (error.code === ApiErrorCode.WRONG_PASSWORD) {
            setPasswordValidationMessage(__('Wrong password'))
          } else if (error.code === ApiErrorCode.TOO_MANY_REQUESTS) {
            setPasswordValidationMessage(__('Too many failed login attempts. Please try again later.'))
          } else if (error.code === ApiErrorCode.INVALID_GOOGLE_APP_LICENSE) {
            setPasswordValidationMessage(__('Sorry, your license is invalid. Contact an admin.'))
          }
        } catch (jsonException) {
          captureException(jsonException)
        }
      } else {
        captureFetchException(e, { source: 'AuthLoginLoginWithPassword' })
      }
    }
  }

  function resetIsAwaitingServerResponse(): void {
    isAwaitingServerResponse.value = false
  }

  return {
    // State
    username,
    password,
    usernameValidationMessage,
    passwordValidationMessage,
    isAwaitingServerResponse,
    isUsernameVerified,
    codeChallenge,
    codeChallengeMethod,
    pkceState,
    originAppClientId,
    originAppRedirectUri,
    schoolLibraryName,

    // Getters
    thirdPartyAuthLoginMethods,
    userThirdPartyAuthLoginMethods,
    samlLoginMethods,
    isUsernameValid,
    isPasswordValid,
    isPkceEnabled,
    isPasswordLoginEnabled,
    isSchoolLibraryLoginPage,

    // Actions
    initialize,
    setUsername,
    setPassword,
    setUsernameValidationMessage,
    setPasswordValidationMessage,
    setIsUsernameVerified,
    continueWithEmailOrUsername,
    loginWithPassword,
    resetIsAwaitingServerResponse,
  }
})
