// @file Helper functions for auth pages
interface LoginMethod {
  name: string
  url?: string
  displayName?: string
  logo?: string
  devMode?: boolean | null
}

const normalizeThirdPartyLoginMethods = (methods: LoginMethod[]): Record<string, LoginMethod> => {
  const loginMethods: Record<string, LoginMethod> = {}

  for (const method of methods) {
    if (method.name === 'password' || method.name.startsWith('saml')) continue
    loginMethods[method.name] = method
  }

  return loginMethods
}

export { normalizeThirdPartyLoginMethods }
export type { LoginMethod }
